import {AxiosRequestConfig, AxiosResponse} from 'axios';
import qs from 'qs';

import axios from './index';

type UploadContext = 'hot_personal_area' | 'hot_ads' | 'search_photo';
type ImgType = 'exs' | 'new';

type BaseImageUploadQuery<T> = {
  context: T;
  img_type: ImgType;
};

export type PromotionImageUploadQuery = BaseImageUploadQuery<UploadContext>;
export type SearchImageUploadQuery = BaseImageUploadQuery<'search_photo'>;

export type PlaceImageUploadQuery = BaseImageUploadQuery<UploadContext> & {
  entity_id: string;
};

export type UploadQuery = PlaceImageUploadQuery | PromotionImageUploadQuery;

type UploadImage = (
  formData: FormData,
  query: UploadQuery,
  onUploadProgress: AxiosRequestConfig['onUploadProgress']
) => Promise<AxiosResponse<any, any>>;

export const uploadImage: UploadImage = (formData, query, onUploadProgress) => {
  const queryString = qs.stringify(query);

  return axios.post(`${ENV_IMAGES_SERVICE_HOST}/upload?${queryString}`, formData, {
    withCredentials: true,
    onUploadProgress,
  });
};

type UploadImageSearch = (
  formData: FormData,
  query: UploadQuery,
  onUploadProgress?: AxiosRequestConfig['onUploadProgress']
) => Promise<AxiosResponse<any, any>>;

export const uploadImageSearch: UploadImageSearch = (formData, query, onUploadProgress) => {
  const queryString = qs.stringify(query);

  return axios.post(`/image/upload?${queryString}`, formData, {
    withCredentials: true,
    onUploadProgress,
  });
};
