import {FC} from 'react';

import IconsMap from './iconsMap';
import {IconProps} from './types';

const Icon: FC<IconProps> = ({name, className, onClick, width, color, height}) => {
  const IconComponent = IconsMap[name];

  if (!IconComponent) return null;

  return (
    <IconComponent
      className={className}
      onClick={onClick}
      {...(color && {
        style: {color},
      })}
      // Hack! undefined values reset height and width to original values
      {...(width && {width})}
      {...(height && {height})}
    />
  );
};

export default Icon;
