import {FC} from 'react';
import clsx from 'clsx';

import Icon, {Icons} from '../../Icon';
import CardBordered from '../CardBordered';
import s from './styles.module.scss';

export enum AlertTypes {
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info',
}

const IconsMap = {
  [AlertTypes.error]: Icons.warningBox,
  [AlertTypes.info]: Icons.warningBox,
  [AlertTypes.success]: Icons.warningBox,
  [AlertTypes.warning]: Icons.warningBox,
};

interface AlertProps {
  type: AlertTypes;
  message: any;
  className?: string;
}

const Alert: FC<AlertProps> = ({type, message, className}) => {
  const IconName = IconsMap[type];

  return (
    <CardBordered className={clsx(s.alert, s[`alert--${type}`], className)}>
      <Icon name={IconName} className={clsx(s.alert__icon, s[`alert__icon--${type}`])} />
      <div className={s.alert__content}>{message}</div>
    </CardBordered>
  );
};

export default Alert;
