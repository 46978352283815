import {FC, useEffect, useRef, useState} from 'react';
import {Flex, Text} from '@mantine/core';
import clsx from 'clsx';
import {format} from 'date-fns';
import {useDispatch} from 'react-redux';

import {NotificationType, TNotification} from '~/services/hot-api/notifications';
import Plate from '~/common/display/Plate';
import {PlateType} from '~/common/display/Plate/Plate';
import Icon, {Icons} from '~/common/Icon';

import {NotificationToPlateMap} from '../../constants';
import NotificationAction from './parts/NotificationAction';
import s from './styles.module.scss';

interface NotificationItemProps {
  notification: TNotification;
  onOpen: () => void;
  onDelete: () => void;
  readed: boolean;
}

const OFFSET_BREAKPOINT = 5;

const NotificationItem: FC<NotificationItemProps> = ({notification, onOpen, onDelete, readed}) => {
  const dispatch = useDispatch();
  const contentRef = useRef<HTMLDivElement>(null);

  const [isClamped, setClamped] = useState(false);
  const notificationType = notification.type || NotificationType.info;
  const plateType = readed
    ? PlateType.transparent
    : NotificationToPlateMap[notificationType].plateType;

  useEffect(() => {
    const textNode = contentRef.current;

    if (!textNode) {
      return;
    }

    if (Math.abs(textNode.scrollHeight - textNode.clientHeight) > OFFSET_BREAKPOINT) {
      setClamped(true);
    }
  }, []);

  const timeAdded = format(new Date(notification.added), 'HH:mm');

  const handleActionClick = () => {
    if (readed) {
      return;
    }
    dispatch.profile.setNotifactionAsReaded(notification.id);
  };

  const handleReadMoreClick = () => {
    if (!readed) {
      dispatch.profile.setNotifactionAsReaded(notification.id);
    }

    onOpen();
  };

  return (
    <div className={s.notification}>
      <Icon name={Icons.crossSmall} className={s['notification__close-icon']} onClick={onDelete} />
      <Plate type={plateType}>
        <div className={s.notification__header} onClick={handleReadMoreClick}>
          <Icon
            name={Icons.infoCircle}
            className={clsx(s.notification__icon, s[`notification__icon--${notificationType}`])}
          />
          <div className={s.notification__title}>{notification.title}</div>
        </div>
        {notification.content && (
          <Text ref={contentRef} lineClamp={3} className={s.notification__content} mt={12}>
            {notification.content}
          </Text>
        )}
        <div className={s.notification__controls}>
          {isClamped && (
            <div className={s['notification__read-more']} onClick={handleReadMoreClick}>
              Read More
            </div>
          )}
          {notification.actions.length === 0 && (
            <div className={s.notifcation__date}>{timeAdded}</div>
          )}
        </div>
        {notification.actions.length > 0 && (
          <Flex mt={20} align="end" justify="space-between">
            <div className={s.notification__actions}>
              {notification.actions.map((action) => (
                <NotificationAction
                  key={action.type}
                  type={action.type}
                  payload={action.payload}
                  onAction={handleActionClick}
                  variant="outline"
                />
              ))}
            </div>
            <div className={s.notifcation__date}>{timeAdded}</div>
          </Flex>
        )}
      </Plate>
    </div>
  );
};

export default NotificationItem;
