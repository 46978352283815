import {FC} from 'react';
import {Popover} from '@mantine/core';

import s from './styles.module.scss';

interface ManageMenuProps {
  content: {
    impersonateLink?: string;
    promotionsLink?: string;
    promotionDetailsLink?: string;
    detailsLink?: string;
  };
}

const MenuItem: FC<any> = ({icon, title, href}) => (
  <a href={href} target="_blank" rel="noreferrer">
    <svg>
      <use xlinkHref={`/img/icons.svg#${icon}`} />
    </svg>
    <span>{title}</span>
  </a>
);

const ManageMenu: FC<ManageMenuProps> = ({content}) => (
  <Popover position="bottom" width={200}>
    <Popover.Target>
      <div className={s['manage-menu']}>
        <svg>
          <use xlinkHref="/img/icons.svg#icon_footer_menu_settings" />
        </svg>
        <div className={s['manage-menu__title']}>Manage</div>
      </div>
    </Popover.Target>

    <Popover.Dropdown className={s['manage-menu__dropdown']}>
      <div className={s['manage-menu__content']}>
        {content.impersonateLink && (
          <MenuItem
            icon="icon_impersonate"
            title="Impersonate User"
            href={content.impersonateLink}
          />
        )}
        {content.promotionsLink && (
          <MenuItem icon="icon_advertise" title="Promotions" href={content.promotionsLink} />
        )}
        {content.promotionDetailsLink && (
          <MenuItem
            icon="icon_advertise"
            title="Promotion Details"
            href={content.promotionDetailsLink}
          />
        )}
        {content.detailsLink && (
          <MenuItem icon="icon_edit" title="User Details" href={content.detailsLink} />
        )}
      </div>
    </Popover.Dropdown>
  </Popover>
);

export default ManageMenu;
