// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu--y71AD{display:flex;z-index:1;gap:24px;align-items:center;position:relative;height:100%}.menu--y71AD .impersonate--zNSfl{justify-content:center;display:flex;align-items:center;height:34px;width:60px;line-height:1;-webkit-box-shadow:inset 0 0 0 2px #f5bc41;box-shadow:inset 0 0 0 2px #f5bc41;border-radius:4px;background:#f5bc41;fill:#1967d2;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/app-header/components/HeaderMenu/styles.module.scss"],"names":[],"mappings":"AAEA,aACE,YAAA,CACA,SAAA,CACA,QAAA,CACA,kBAAA,CACA,iBAAA,CACA,WAAA,CAEA,iCACE,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CACA,aAAA,CACA,0CAAA,CACA,kCAAA,CACA,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,cAAA","sourcesContent":["@import '~/utils/mixin.scss';\n\n.menu {\n  display: flex;\n  z-index: 1;\n  gap: 24px;\n  align-items: center;\n  position: relative;\n  height: 100%;\n\n  .impersonate {\n    justify-content: center;\n    display: flex;\n    align-items: center;\n    height: 34px;\n    width: 60px;\n    line-height: 1;\n    -webkit-box-shadow: inset 0 0 0 2px #f5bc41;\n    box-shadow: inset 0 0 0 2px #f5bc41;\n    border-radius: 4px;\n    background: #f5bc41;\n    fill: #1967d2;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `menu--y71AD`,
	"impersonate": `impersonate--zNSfl`
};
export default ___CSS_LOADER_EXPORT___;
