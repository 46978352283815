import {Icons} from '~/common/Icon';
import {PlateType} from '~/common/display/Plate/Plate';
import {NotificationType} from '~/services/hot-api/notifications';

export const NotificationToPlateMap = {
  [NotificationType.error]: {
    icon: Icons.infoCircleNew,
    plateType: PlateType.error,
  },
  [NotificationType.info]: {
    icon: Icons.infoCircleNew,
    plateType: PlateType.info,
  },
  [NotificationType.success]: {
    icon: Icons.infoCircleNew,
    plateType: PlateType.success,
  },
};
