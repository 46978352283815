import {FC, ReactNode} from 'react';
import {Box, Skeleton} from '@mantine/core';
import {format} from 'date-fns';
import {useDispatch} from 'react-redux';

import {TNotification, TNotificationsResponse} from '~/services/hot-api/notifications';
import {InfinityScroll} from '~/common/display';
import Loader from '~/common/Loader';
import {Dispatch} from '~/store/types';

import NotificationItem from '../NotificationItem/NotificationItem';
import s from './styles.module.scss';

interface NotificationsListProps {
  notifications: TNotificationsResponse;
  loading: boolean;
  onNotificationOpen: (notification: TNotification) => void;
  onNotificationDelete: (notification: TNotification) => void;
}

const NotificationsList: FC<NotificationsListProps> = ({
  loading,
  notifications,
  onNotificationOpen,
  onNotificationDelete,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const {items, total} = notifications;

  const handleLoadMore = () => {
    if (total <= items.length) {
      return;
    }

    dispatch.profile.loadMoreNotifications();
  };

  if (loading && items.length === 0) {
    return (
      <div className={s['notifications-list']}>
        <Box className={s['notifications-list__skeletons']}>
          <Skeleton height={100} className={s.notifcation} />
          <Skeleton height={100} className={s.notifcation} />
          <Skeleton height={100} className={s.notifcation} />
        </Box>
      </div>
    );
  }

  if (!loading && items.length === 0) {
    return (
      <div className={s['notifications-list']}>
        <Box>All caught up! No new notifications.</Box>
      </div>
    );
  }

  // Формируем список нотификаций с дополнительным хеадром с датой для каждого дня
  const listNodes = items.reduce<ReactNode[]>((acc, notification, index) => {
    const prevNotify = items[index - 1];

    if (prevNotify) {
      const dataPrev = format(new Date(prevNotify.added), 'MMMM d');
      const current = format(new Date(notification.added), 'MMMM d');

      if (current !== dataPrev) {
        acc.push(
          <div key={current} className={s['notification-list__day-title']}>
            {current}
          </div>
        );
      }
    }

    acc.push(
      <NotificationItem
        key={notification.id}
        notification={notification}
        onOpen={() => onNotificationOpen(notification)}
        onDelete={() => onNotificationDelete(notification)}
        readed={Boolean(notification.readed || notification.viewed)}
      />
    );

    return acc;
  }, []);

  if (loading) {
    listNodes.push(<Loader mt={8} key="loader" variant="dots" />);
  }

  return (
    <InfinityScroll
      loading={loading}
      className={s['notifications-list']}
      onLoadMore={handleLoadMore}
    >
      {listNodes}
    </InfinityScroll>
  );
};

export default NotificationsList;
