import {FC} from 'react';
import clsx from 'clsx';

import EngineSwitch from '~/app-header/components/EngineSwitch/EngineSwitch';
import ManageMenu from '~/app-header/components/ManageMenu';
import MainLogo from '~/app-header/components/MainLogo';
import SearchForm from '~/app-header/components/HeaderMenu/parts/SearchForm';
import HeaderMenu from '~/app-header/components/HeaderMenu';

import s from '../../styles.module.scss';

interface MobileSearchHeaderProps {
  manageMenu: any;
  impersonate: any;
  mail: any;
  form: any;
  onSubmit: any;
  searchForm: any;
  isWorker: boolean;
}

const MobileSearchHeader: FC<MobileSearchHeaderProps> = ({
  manageMenu,
  form,
  onSubmit,
  searchForm,
  impersonate,
  mail,
  isWorker,
}) => {
  const handleEngineChange = () => {
    form.handleSubmit(onSubmit)();
  };

  return (
    <div className={clsx(s['header-root'], s['search-header'])}>
      <div className={s['search-header__inner']}>
        <div className={s['search-header__logo-wrap']}>
          <MainLogo variant="small" className={s['search-header__logo']} />
          {isWorker && <EngineSwitch onChange={handleEngineChange} />}
        </div>
        <div className={s['search-header__mobile-menu']}>
          {manageMenu.visible && manageMenu.content && <ManageMenu content={manageMenu.content} />}
          <HeaderMenu impersonate={impersonate} mail={mail} />
        </div>
      </div>
      <SearchForm
        form={form}
        onSubmit={onSubmit}
        photoSearch={searchForm.searchByPhoto}
        photoSearchBlob={searchForm.searchByPhotoBlob}
      />
    </div>
  );
};

export default MobileSearchHeader;
