import {FC} from 'react';
import clsx from 'clsx';

import Icon, {Icons} from '../Icon';
import {HeaderProps} from './types';
import s from './style.module.scss';

const Header: FC<HeaderProps> = ({
  className,
  showAds,
  showBalance,
  badgesClassName,
  onClose,
  avatar,
  firstName,
  lastName,
  location,
  balance,
  isMenuLocked,
  count: {ads, reviews, favorites, questions},
}) => {
  const handleLinkClick = (event: MouseEvent) => {
    if (isMenuLocked) {
      event.preventDefault();
    }
  };

  return (
    <div className={clsx(s.header, className)}>
      <div className={s['header-photo']}>
        {avatar ? (
          <img src={avatar} alt="avatar" />
        ) : (
          <svg className={s['photo-not-found']}>
            <use xlinkHref="/img/icons.svg#personal-area__avatar" />
          </svg>
        )}
      </div>
      <div className={s.header__content}>
        <div className={s.header__name}>
          <span className={s['header__name-content']}>
            {firstName} {lastName}
          </span>
          <span className={s['header__name-change']} />
        </div>
        <div className={s.header__location}>{location}</div>
        {showBalance && (
          <div className={s.header__balance}>Account Balance: ${(balance || 0).toFixed(2)}</div>
        )}
      </div>
      {onClose && <Icon name={Icons.cross} className={s['header__close-btn']} onClick={onClose} />}
      <div className={clsx(s['user-area__badges'], badgesClassName)}>
        {showAds && (
          <a href="/profile/dashboard" onClick={handleLinkClick} className={s.badges__item}>
            <div className={s.badges__number}>{ads || 0}</div>
            <div className={s.badges__text}>Active Ads</div>
          </a>
        )}
        <a href="/profile#/my-reviews" onClick={handleLinkClick} className={s.badges__item}>
          <div className={s.badges__number}>{reviews || 0}</div>
          <div className={s.badges__text}>Reviews</div>
        </a>
        <div className={s.badges__item}>
          <div className={s.badges__number}>{favorites || 0}</div>
          <div className={s.badges__text}>Favorites</div>
        </div>
        <a href="/profile#/my-questions" onClick={handleLinkClick} className={s.badges__item}>
          <div className={s.badges__number}>{questions || 0}</div>
          <div className={s.badges__text}>Questions</div>
        </a>
      </div>
    </div>
  );
};
export default Header;
