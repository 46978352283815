/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';
import clsx from 'clsx';
import {useMediaQuery} from '@mantine/hooks';
import {useDispatch, useSelector} from 'react-redux';

import Icon, {Icons} from '~/common/Icon';
import {RootState} from '~/store/types';
import {WebsiteThemes} from '~/utils/constants';
import {isMobileDevice} from '~/utils/browserDetect';
import useWindowLandscape from '~/hooks/useWindowLandscape';
import {Modals} from '~/modals/constants';

import s from './styles.module.scss';

const GuestMenu: FC<{isSnackbarVisible?: boolean}> = ({isSnackbarVisible}) => {
  const dispatch = useDispatch();
  const {theme, data} = useSelector((store: RootState) => store.app);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isLandscape = useWindowLandscape();

  // Hack!!!
  const handleClose = () => {
    document.dispatchEvent(new Event('click'));
  };

  return (
    <div
      className={clsx(s.wrapper, {
        [s['wrapper--shifted']]: isSnackbarVisible,
        [s['wrapper--landscape']]: isLandscape && isMobileDevice(),
      })}
    >
      <div>
        <div className={s['icon-wrapper']}>
          <Icon name={Icons.cross} className={s['close-icon']} onClick={handleClose} />
        </div>
        <li className="teeny-menu-iconed__item" id="sign_in">
          <a href="/signin" className="teeny-menu-iconed__link">
            <div className="teeny-menu-iconed__icon-wrapper">
              <Icon
                name={Icons.guestSignInBold}
                className={clsx('teeny-menu-iconed__icon', 'teeny-menu-iconed__icon_signin_bold')}
              />
            </div>
            <div className={clsx('teeny-menu-iconed__title', s['menu-item'])}>Sign in</div>
          </a>
        </li>
        {/* Temorary hidden */}
        {/* <li className="teeny-menu-iconed__item" id="sign_up">
          <a href="/signup?from=%2F" className="teeny-menu-iconed__link">
            <div className="teeny-menu-iconed__icon-wrapper">
              <Icon
                name={Icons.guestSignUpBold}
                className={clsx('teeny-menu-iconed__icon', 'teeny-menu-iconed__icon_signup_bold')}
              />
            </div>
            <div className={clsx('teeny-menu-iconed__title', s['menu-item'])}>Sign up</div>
          </a>
        </li> */}
        <li className="teeny-menu-iconed__item  " id="advertise">
          <a href="/advertiser-signup" className="teeny-menu-iconed__link">
            <div className="teeny-menu-iconed__icon-wrapper">
              <Icon
                name={Icons.guestAdvertise}
                className={clsx(
                  'teeny-menu-iconed__icon',
                  'teeny-menu-iconed__icon_advertise_bold'
                )}
              />
            </div>
            <div className={clsx('teeny-menu-iconed__title', s['menu-item'])}>Advertise</div>
          </a>
        </li>
        <li className="teeny-menu-iconed__item" id="sign_in">
          <a
            href="/profile#/business-confirm"
            className={clsx('teeny-menu-iconed__link', s['menu-link-disabled'])}
          >
            <div className="teeny-menu-iconed__icon-wrapper">
              <Icon
                name={Icons.guestAddBusiness}
                className={clsx('teeny-menu-iconed__icon', 'teeny-menu-iconed__icon_signin_bold')}
              />
            </div>
            <div className={clsx('teeny-menu-iconed__title', s['menu-item'])}>Claim Business</div>
          </a>
        </li>
        <li className="teeny-menu-iconed__item" id="add_your_business">
          <a
            className="teeny-menu-iconed__link"
            href="#"
            onClick={() => {
              if (isMobile) {
                window.location.href = '/add-business';
              } else {
                $.hotLoad({
                  url: '/site/add-business',
                  container: $('#pjax_root'),
                  fragment: `#add_business_root`,
                  completeHotLoad() {
                    $('body').addClass('popmod_body');
                    $('#pjax_root').parent().addClass('popmod_visible');
                  },
                });
                handleClose();
              }
            }}
          >
            <div className="teeny-menu-iconed__icon-wrapper">
              <Icon
                name={Icons.guestAddBusinessBold}
                className={clsx(
                  'teeny-menu-iconed__icon',
                  'teeny-menu-iconed__icon_add_business_bold'
                )}
              />
            </div>
            <div className={clsx('teeny-menu-iconed__title', s['menu-item'])}>
              Add Your Business
            </div>
          </a>
        </li>
        <li className={clsx('teeny-menu-iconed__item', s.faq)} id="faq">
          <a href="https://help.hot.com" className="teeny-menu-iconed__link">
            <div className="teeny-menu-iconed__icon-wrapper">
              <Icon
                name={Icons.guestFaqBold}
                className={clsx('teeny-menu-iconed__icon', 'teeny-menu-iconed__icon_faq_bold')}
              />
            </div>
            <div className={clsx('teeny-menu-iconed__title', s['menu-item'])}>FAQ</div>
          </a>
        </li>
      </div>
      <div style={{borderTop: '1px solid #D9D9D9'}}>
        {/* Hide this menu for arabic */}
        {theme !== WebsiteThemes.THEME_CAMEL && (
          <li className="teeny-menu-iconed__item blue-links">
            <a
              href="/search-bar"
              className={clsx('teeny-menu-iconed__link', s.link)}
              target="_blank"
            >
              Grab Our Banner
            </a>
          </li>
        )}
        <li className="teeny-menu-iconed__item blue-links">
          <a
            href="/privacy-policy"
            className={clsx('teeny-menu-iconed__link', s.link)}
            target="_blank"
          >
            Terms &amp; Policy
          </a>
        </li>
        <li className="teeny-menu-iconed__item blue-links">
          <a
            onClick={() => {
              handleClose();
              dispatch.modals.showModal(Modals.RemovalRequestModal);
            }}
            className={clsx('teeny-menu-iconed__link', s.link)}
          >
            Removal Request
          </a>
        </li>
        <li className="teeny-menu-iconed__item blue-links">
          <a
            onClick={() => {
              handleClose();
              dispatch.modals.showModal(Modals.ContactUsModal);
            }}
            className={clsx('teeny-menu-iconed__link', s.link)}
          >
            Contact Us
          </a>
        </li>
        <div className={clsx('teeny-menu-iconed__copyright', s['menu-item-copyright'])}>
          © {new Date().getFullYear()} {data.instanceInfo.label}
        </div>
      </div>
    </div>
  );
};

export default GuestMenu;
