import {FC} from 'react';
import clsx from 'clsx';
import {shallowEqual, useSelector} from 'react-redux';

import {RootState} from '~/store/types';

import MainLogo from '../../MainLogo';
import HeaderMenu from '../../HeaderMenu';
import s from '../styles.module.scss';

const PersonalAreaContent: FC = () => {
  const {impersonate, mail, balance} = useSelector(
    ({app}: RootState) => ({
      impersonate: app.data.impersonate,
      mail: app.data.mail,
      balance: app.data.balance,
    }),
    shallowEqual
  );

  return (
    <div className={clsx(s['header-root'], s['personal-area-header'])}>
      <MainLogo variant="small" />
      <HeaderMenu impersonate={impersonate} mail={mail} balance={balance} />
    </div>
  );
};

export default PersonalAreaContent;
