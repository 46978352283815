/* eslint-disable react/button-has-type */
import {FC, MouseEventHandler} from 'react';
import clsx from 'clsx';

import Icon, {Icons} from '~/common/Icon';

import {ButtonProps} from './types';
import s from './styles.module.scss';

const Button: FC<ButtonProps> = ({
  className,
  style,
  disabled,
  onClick,
  transparent,
  border,
  type = 'button',
  variant = 'normal',
  children,
  loading,
}) => {
  const classes = clsx(
    s.button,
    {
      [s.transparent]: transparent || variant === 'outline',
      [s.border]: border || variant === 'outline',
    },
    className
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (loading) {
      event.preventDefault();
      return;
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled || loading}
      className={classes}
      style={style}
    >
      {loading ? <Icon className={s.loader} name={Icons.spinner} /> : children}
    </button>
  );
};

export default Button;
