import {ActionIcon, Indicator} from '@mantine/core';
import {FC, forwardRef} from 'react';
import clsx from 'clsx';

import Icon, {Icons} from '~/common/Icon';

import s from '../styles.module.scss';

interface NotificationButtonProps {
  onClick: () => void;
  count: number;
  className?: string;
}

const NotificationButton: FC<NotificationButtonProps> = forwardRef(
  ({onClick, count, className}, ref) => {
    const button = (
      <ActionIcon
        variant="default"
        className={clsx(s['notifications-button'], className)}
        ref={ref as any}
        onClick={onClick}
      >
        <Icon name={Icons.notificationBell} />
      </ActionIcon>
    );

    if (count === 0) {
      return button;
    }

    return (
      <Indicator
        label={count}
        size={20}
        color="#F90"
        offset={5}
        zIndex={1050}
        styles={{
          indicator: {
            lineHeight: '1.25rem',
          },
        }}
      >
        {button}
      </Indicator>
    );
  }
);

export default NotificationButton;
