import {FC} from 'react';
import clsx from 'clsx';
import {shallowEqual, useSelector} from 'react-redux';

import {RootState} from '~/store/types';
import {WebsiteThemes} from '~/utils/constants';

import HeaderMenu from '../../HeaderMenu';
import MainLogo from '../../MainLogo';
import s from '../styles.module.scss';

const MainHeaderContent: FC = () => {
  const {impersonate, mail, theme} = useSelector(
    ({app}: RootState) => ({
      impersonate: app.data.impersonate,
      mail: app.data.mail,
      theme: app.theme,
    }),
    shallowEqual
  );

  return (
    <div className={clsx(s['header-root'], s['header-root--main'])}>
      {theme !== WebsiteThemes.THEME_CYBERPUNK && <MainLogo className={s['header-root__logo']} />}
      <div style={{flex: 1}} />
      <HeaderMenu
        className={s['main-heeader-menu']}
        notifyButtonClassName={s['notify-button']}
        impersonate={impersonate}
        mail={mail}
      />
    </div>
  );
};

export default MainHeaderContent;
