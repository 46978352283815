import {FC} from 'react';
import clsx from 'clsx';
import {shallowEqual, useSelector} from 'react-redux';

import {RootState} from '~/store/types';

import LogoSvg from './svg/logo.svg';
import LogoSvgGay from './svg/logo-gay.svg';
import LogoSvgTrans from './svg/logo-trans.svg';
import s from './styles.module.scss';
import { WebsiteThemes } from '~/utils/constants'

interface MainLogoProps {
  variant?: 'normal' | 'small';
  className?: string;
  showLogo?: boolean;
}

const MainLogo: FC<MainLogoProps> = ({variant, className, showLogo = true}) => {
  const {title, logoType, theme, isRootPage} = useSelector(
    ({app}: RootState) => ({
      title: app.data.logo.title,
      visible: app.data.logo.display,
      logoType: app.data.page.pageCategory,
      isRootPage: app.data.page.isRootPage,
      theme: app.data.theme,
    }),
    shallowEqual
  );

  let LogoContent = LogoSvg;
  if (logoType === 'gay-escorts') {
    LogoContent = LogoSvgGay;
  }

  if (logoType === 'trans-escorts') {
    LogoContent = LogoSvgTrans;
  }

  const rootClassNames = clsx(
    s['main-logo'],
    {
      [s['main-logo--small']]: variant === 'small',
    },
    className
  );

  return (
    <div className={rootClassNames}>
      {theme === WebsiteThemes.THEME_BLACK && isRootPage ? (
        ''
      ) : (
        <a className={s['main-logo__link']} href="/" title={title}>
          <LogoContent />
        </a>
      )}
    </div>
  );
};

export default MainLogo;
