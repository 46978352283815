/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import {FC} from 'react';

import {
  AnyIcon,
  Icons,
  MenuIcons,
  NotificationIcons,
  PromotionIcons,
  SidebarIcons,
  UIKitIcons,
} from './types';

type SVGIcon = FC<React.SVGAttributes<SVGElement>>;

const iconsMap: Record<AnyIcon, SVGIcon> = {
  [Icons.hot]: require('./svg/ads.svg').default,
  [Icons.pin]: require('./svg/pin.svg').default,
  [Icons.lock]: require('./svg/lock.svg').default,
  [Icons.phone]: require('./svg/phone.svg').default,
  [Icons.envelope]: require('./svg/envelope.svg').default,
  [Icons.faq]: require('./svg/faq.svg').default,
  [Icons.hotlink]: require('./svg/hotlinks-icon.svg').default,
  [Icons.star]: require('./svg/star.svg').default,
  [Icons.qa]: require('./svg/qa.svg').default,
  [Icons.fav]: require('./svg/fav.svg').default,
  [Icons.bell]: require('./svg/bell.svg').default,
  [Icons.avatar]: require('./svg/avatar.svg').default,
  [Icons.burgerDots]: require('./svg/burger-dots.svg').default,
  [Icons.sign]: require('./svg/sign.svg').default,
  [Icons.replies]: require('./svg/replies.svg').default,
  [Icons.cross]: require('./svg/cross.svg').default,
  [Icons.crossSmall]: require('./svg/cross-small.svg').default,
  [Icons.crossBold]: require('./svg/cross-bold.svg').default,
  [Icons.home]: require('./svg/home.svg').default,
  [Icons.homePlus]: require('./svg/home-plus.svg').default,
  [Icons.spinner]: require('./svg/spinner.svg').default,
  [Icons.location]: require('./svg/location.svg').default,
  [Icons.link]: require('./svg/link.svg').default,
  [Icons.checkmark]: require('./svg/checkmark.svg').default,
  [Icons.circleAdd]: require('./svg/circle-add.svg').default,
  [Icons.pencil]: require('./svg/pencil.svg').default,
  [Icons.search]: require('./svg/search.svg').default,
  [Icons.searchBlue]: require('./svg/search-blue.svg').default,
  [Icons.menu]: require('./svg/menu.svg').default,
  [Icons.hotLoader]: require('./svg/hot-loader.svg').default,
  [Icons.carretBack]: require('./svg/carret-back.svg').default,
  [Icons.carretDown]: require('./svg/carret-down.svg').default,
  [Icons.phoneSmall]: require('./svg/phone-small.svg').default,
  [Icons.accordionCarret]: require('./svg/accordion-carret.svg').default,
  [Icons.circleCross]: require('./svg/circle-cross.svg').default,
  [Icons.dots]: require('./svg/dots.svg').default,
  [Icons.notFound]: require('./svg/not-found.svg').default,
  [Icons.questionCircle]: require('./svg/question-circle.svg').default,
  [Icons.infoCircle]: require('./svg/info-circle.svg').default,
  [Icons.warningBox]: require('./svg/warning-box.svg').default,
  [Icons.infoCircleNew]: require('./svg/info-circle-new.svg').default,
  [Icons.pride]: require('./svg/pride.svg').default,
  [Icons.notificationBell]: require('./svg/notification-bell.svg').default,
  [Icons.send]: require('./svg/send.svg').default,

  // Ui Kit
  [UIKitIcons.phone]: require('./svg/ui/phone.svg').default,
  [UIKitIcons.question]: require('./svg/ui/question.svg').default,
  [UIKitIcons.review]: require('./svg/ui/review.svg').default,
  [UIKitIcons.warmUp]: require('./svg/ui/warm-up.svg').default,
  [UIKitIcons.cellPhone]: require('./svg/ui/cell-phone.svg').default,
  [UIKitIcons.messageStar]: require('./svg/ui/message-star.svg').default,

  // Profile Socials
  [Icons.socialFacebook]: require('./svg/social/facebook.svg').default,
  [Icons.socialInstagram]: require('./svg/social/instagram.svg').default,
  [Icons.socialLoyalfans]: require('./svg/social/loyalfans.svg').default,
  [Icons.socialTelegram]: require('./svg/social/telegram.svg').default,
  // [Icons.socialTwitter]: require('./svg/social/twitter.svg').default,
  [Icons.socialTer]: require('./svg/social/ter.svg').default,
  [Icons.socialTiktok]: require('./svg/social/tiktok.svg').default,
  [Icons.socialTransfans]: require('./svg/social/transfans.svg').default,
  [Icons.socialWhatsapp]: require('./svg/social/whatsapp.svg').default,
  [Icons.socialWechat]: require('./svg/social/wechat.svg').default,
  [Icons.socialLine]: require('./svg/social/line.svg').default,
  [Icons.socialTumblr]: require('./svg/social/tumblr.svg').default,

  [Icons.mediaCross]: require('./svg/media-cross.svg').default,
  [Icons.mediaPlay]: require('./svg/media-play.svg').default,

  [Icons.socialEditLine]: require('./svg/social-edit/line.svg').default,
  [Icons.socialEditTumblr]: require('./svg/social-edit/tumblr.svg').default,
  [Icons.socialEditWhatsapp]: require('./svg/social-edit/whatsapp.svg').default,
  [Icons.socialEditFacebook]: require('./svg/social-edit/facebook.svg').default,
  [Icons.socialEditInstagram]: require('./svg/social-edit/instagram.svg').default,
  [Icons.socialEditTelegram]: require('./svg/social-edit/telegram.svg').default,
  [Icons.socialEditWeechat]: require('./svg/social-edit/weechat.svg').default,
  [Icons.socialOnlyfans]: require('./svg/social/onlyfans.svg').default,

  [Icons.circlePlus]: require('./svg/circle-plus.svg').default,
  [Icons.trash]: require('./svg/trash.svg').default,
  [Icons.plus]: require('./svg/plus.svg').default,

  // hamburger-menu
  [Icons.accountSettings]: require('./svg/menu/account-settings.svg').default,
  [Icons.advertiseOnHot]: require('./svg/menu/advertise-on-hot.svg').default,
  [Icons.adStatistic]: require('./svg/menu/ad-statistic.svg').default,
  [Icons.addBusiness]: require('./svg/menu/add-business.svg').default,
  [Icons.adsDashboard]: require('./svg/menu/ads-dashboard.svg').default,
  [Icons.changeEmail]: require('./svg/menu/change-email.svg').default,
  [Icons.changePassword]: require('./svg/menu/change-password.svg').default,
  [Icons.changePhone]: require('./svg/menu/change-phone.svg').default,
  [Icons.changePinCode]: require('./svg/menu/change-pin-code.svg').default,
  [Icons.editHotlinksPage]: require('./svg/menu/edit-hotlinks-page.svg').default,
  [Icons.generalInvoice]: require('./svg/menu/general-invoice.svg').default,
  [Icons.logout]: require('./svg/menu/logout.svg').default,
  [Icons.menuFaq]: require('./svg/menu/menu-faq.svg').default,
  [Icons.myAccount]: require('./svg/menu/my-account.svg').default,
  [Icons.myAds]: require('./svg/menu/my-ads.svg').default,
  [Icons.myFavorites]: require('./svg/menu/favorites.svg').default,
  [Icons.myHotlinks]: require('./svg/menu/my-hotlinks.svg').default,
  [Icons.myMailbox]: require('./svg/menu/my-mailbox.svg').default,
  [Icons.myNotifications]: require('./svg/menu/my-notifications.svg').default,
  [Icons.myQa]: require('./svg/menu/my-qa.svg').default,
  [Icons.myReviews]: require('./svg/menu/my-reviews.svg').default,
  [Icons.paidInvoices]: require('./svg/menu/paid-invoices.svg').default,
  [Icons.payInvoices]: require('./svg/menu/pay-invoices.svg').default,
  [Icons.postAd]: require('./svg/menu/post-ad.svg').default,
  [Icons.postMobileMenuAd]: require('./svg/menu/post-mobile-menu-ad.svg').default,
  [Icons.postTextAd]: require('./svg/menu/post-text-ad.svg').default,
  [Icons.promoteImages]: require('./svg/menu/promote-images.svg').default,
  [Icons.promoteUrl]: require('./svg/menu/promote-url.svg').default,
  [Icons.setUpBitcoinAddress]: require('./svg/menu/set-up-bitcoin-address.svg').default,
  [Icons.subscribers]: require('./svg/menu/subscribers.svg').default,
  [Icons.transactions]: require('./svg/menu/transactions.svg').default,
  [Icons.carretLeft]: require('./svg/menu/carret-left.svg').default,
  [Icons.carretRight]: require('./svg/menu/carret-right.svg').default,
  [Icons.clear]: require('./svg/clear.svg').default,
  [Icons.photo]: require('./svg/photo.svg').default,
  [Icons.dragndrop]: require('./svg/dragndrop.svg').default,
  [Icons.searchPhoto]: require('./svg/search-photo.svg').default,

  // logo
  [Icons.hotlinksLogo]: require('./svg/logo/hotlinks.svg').default,

  // Guest Menu
  [Icons.guestAddBusinessBold]: require('./svg/guest-menu/add-business-bold.svg').default,
  [Icons.guestAddBusiness]: require('./svg/guest-menu/add-business.svg').default,
  [Icons.guestAdvertise]: require('./svg/guest-menu/advertise.svg').default,
  [Icons.guestFaqBold]: require('./svg/guest-menu/faq-bold.svg').default,
  [Icons.guestSignInBold]: require('./svg/guest-menu/sign-in-bold.svg').default,
  [Icons.guestSignUpBold]: require('./svg/guest-menu/sign-up-bold.svg').default,

  // Form - Radio
  [Icons.formRadio]: require('./svg/form/radio/radio.svg').default,
  [Icons.formRadioChecked]: require('./svg/form/radio/radio-checked.svg').default,

  // Form - Checkbox
  [Icons.formCheckbox]: require('./svg/form/checkbox/checkbox.svg').default,
  [Icons.formCheckboxChecked]: require('./svg/form/checkbox/checkbox-checked.svg').default,
  [Icons.formCheckboxPartial]: require('./svg/form/checkbox/checkbox-partial.svg').default,

  // Menu
  [MenuIcons.connectedProfiles]: require('./svg/menu/connected-profiles.svg').default,

  // Sidebar
  [SidebarIcons.dashboard]: require('./svg/sidebar/dashboard.svg').default,
  [SidebarIcons.faq]: require('./svg/sidebar/faq.svg').default,
  [SidebarIcons.favorites]: require('./svg/sidebar/favorites.svg').default,
  [SidebarIcons.funds]: require('./svg/sidebar/funds.svg').default,
  [SidebarIcons.impportApi]: require('./svg/sidebar/import-api.svg').default,
  [SidebarIcons.invoiceGenerate]: require('./svg/sidebar/invoice-generate.svg').default,
  [SidebarIcons.invoicePaid]: require('./svg/sidebar/invoice-paid.svg').default,
  [SidebarIcons.invoicePay]: require('./svg/sidebar/invoice-pay.svg').default,
  [SidebarIcons.logout]: require('./svg/sidebar/logout.svg').default,
  [SidebarIcons.myQuestions]: require('./svg/sidebar/my-qa.svg').default,
  [SidebarIcons.myReviews]: require('./svg/sidebar/my-reviews.svg').default,
  [SidebarIcons.myHotlinks]: require('./svg/sidebar/my-hotlinks.svg').default,
  [SidebarIcons.settings]: require('./svg/sidebar/settings.svg').default,
  [SidebarIcons.statistics]: require('./svg/sidebar/statistics.svg').default,
  [SidebarIcons.transactions]: require('./svg/sidebar/transactions.svg').default,
  [SidebarIcons.businessList]: require('./svg/sidebar/business-list.svg').default,
  [SidebarIcons.businessClaim]: require('./svg/sidebar/business-claim.svg').default,
  [SidebarIcons.connectedProfiles]: require('./svg/sidebar/connected-profiles.svg').default,

  [PromotionIcons.promoBoost]: require('./svg/promotions/promo-boost.svg').default,
  [PromotionIcons.promoImage]: require('./svg/promotions/promo-image.svg').default,
  [PromotionIcons.promoMenu]: require('./svg/promotions/promo-menu.svg').default,
  [PromotionIcons.promoText]: require('./svg/promotions/promo-text.svg').default,
  [PromotionIcons.promoTopspot]: require('./svg/promotions/promo-topspot.svg').default,
  [PromotionIcons.promoUrl]: require('./svg/promotions/promo-url.svg').default,

  [NotificationIcons.success]: require('./svg/notifications/success.svg').default,
};

export default iconsMap;
