import {FC} from 'react';
import clsx from 'clsx';
import {Text} from '@mantine/core';
import {format} from 'date-fns';

import {NotificationType, TNotification} from '~/services/hot-api/notifications';
import Modal from '~/common/display/Modal';
import Icon, {Icons} from '~/common/Icon';

import s from './styles.module.scss';
import NotificationAction from '../NotificationItem/parts/NotificationAction';

interface NotificationModalProps {
  opened: boolean;
  onClose: () => void;
  notification: TNotification;
}

const NotificationModal: FC<NotificationModalProps> = ({opened, onClose, notification}) => {
  const notificationType = notification.type || NotificationType.info;

  return (
    <Modal opened={opened} onClose={onClose} className={s['notification-modal']} centered>
      <div className={s.notification__header}>
        <Icon
          name={Icons.infoCircle}
          className={clsx(s.notification__icon, s[`notification__icon--${notificationType}`])}
        />
        <div>
          <div className={s.notification__title}>{notification.title}</div>
        </div>
      </div>
      <div>
        <Text className={s.notification__content} mt={12}>
          {notification.content}
        </Text>
        <div className={s['notification-modal__date']}>
          {format(new Date(notification.added), 'MMMM d, HH:mm')}
        </div>
      </div>
      <div className={s.notification__actions}>
        {notification.actions.map((action) => (
          <NotificationAction key={action.type} type={action.type} payload={action.payload} />
        ))}
      </div>
    </Modal>
  );
};

export default NotificationModal;
