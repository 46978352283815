import {FC} from 'react';

import Button from '~/common/Button';

import s from '../styles.module.scss';

const NotifcationActionsMap = {
  edit_promo: {
    label: 'Edit Promo',
    callback: (url: string) => {
      window.location.href = url;
    },
  },
  charge_balance: {
    label: 'Charge Balance',
    callback: (url: string) => {
      window.location.href = url;
    },
  },
  pay_promo: {
    label: 'Pay Promo',
    callback: (url: string) => {
      window.location.href = url;
    },
  },
};

const ButtonAction: FC<any> = ({label, url, onAction, variant}) => {
  const buttonData = NotifcationActionsMap[label];

  const handleButtonClick = async () => {
    if (onAction) {
      await onAction(url);
    }
    buttonData.callback(url);
  };

  if (!buttonData) {
    return null;
  }

  return (
    <Button className={s.notifcation__button} variant={variant} onClick={handleButtonClick}>
      {buttonData.label}
    </Button>
  );
};

export default ButtonAction;
