import {FC} from 'react';

import {NotificationActionType, NotificationPayloadMap} from '~/services/hot-api/notifications';

import ButtonAction from './ButtonAction';

const ActionsMap = {
  [NotificationActionType.Button]: ButtonAction,
};

interface NotificationActionProps {
  type: keyof typeof ActionsMap;
  payload: NotificationPayloadMap;
  onAction: () => void;
  variant?: 'normal' | 'outline';
}

const NotificationAction: FC<NotificationActionProps> = ({type, payload, onAction, variant}) => {
  const Component = ActionsMap[type];

  if (!Component) {
    return null;
  }

  return <Component {...payload} onAction={onAction} variant={variant} />;
};

export default NotificationAction;
