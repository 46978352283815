import {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import clsx from 'clsx';

import {RootState} from '~/store/types';
import Button from '~/common/Button';
import Input from '~/common/Input';
import Textarea from '~/common/Form/Textarea';
import FormPhoneInput from '~/common/Form/PhoneInput';
import Alert, {AlertTypes} from '~/common/display/Alert';
import {validateEmail} from '~/utils/validate';
import {ensurePlus} from '~/utils/phone';
import {formatUserName} from '~/utils/name';
import {getCaptchaValue, initCaptcha} from '~/utils/captcha';
import {useErrorAutoHide} from '~/hooks/useErrorAutoHide';
import {
  Modals,
  CONTACT_US_CAPTCHA_ID,
  CONTACT_US_CAPTCHA_NAMESPACE,
  SERVER_ERROR,
} from '~/modals/constants';

import s from '../styles.module.scss';

export interface ContactUsFormValues {
  name?: string;
  email?: string;
  phone?: string;
  text: string;
  captcha?: string;
}

/**
 * @param queryData Optional search query params to submit
 * @constructor
 */
const ContactUsForm: FC = (queryData: object) => {
  const dispatch = useDispatch();
  const {error, errorCaptcha} = useSelector((state: RootState) => state.contactUs);
  const {isGuest, data} = useSelector((state: RootState) => state.profile);
  const serverError = useErrorAutoHide(error);

  // populate user details in form
  const contactUsDefaultValues: ContactUsFormValues = {
    name: formatUserName(data),
    phone: ensurePlus((data && data.phone) ?? ''),
    email: data ? data.email : '',
    text: '',
    captcha: '',
  };

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: {errors, isValid, isSubmitting},
  } = useForm<ContactUsFormValues>({
    defaultValues: contactUsDefaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch.contactUs.setContactUsDefaultState();

    if (isGuest) {
      initCaptcha(`#${CONTACT_US_CAPTCHA_ID}`, CONTACT_US_CAPTCHA_NAMESPACE, () => {
        setValue('captcha', '');
      });
    }
  }, []);

  useEffect(() => {
    if (errorCaptcha) {
      setError('captcha', {type: 'server'});
    }
  }, [errorCaptcha]);

  useEffect(() => {
    if (serverError) {
      initCaptcha(`#${CONTACT_US_CAPTCHA_ID}`, CONTACT_US_CAPTCHA_NAMESPACE, () => {
        setValue('captcha', '');
      });
    }
  }, [serverError]);

  const onSubmit: SubmitHandler<ContactUsFormValues> = async (formValues: ContactUsFormValues) =>
    dispatch.contactUs.contactUsSave({
      ...formValues,
      ...queryData,
    });

  const hideModal = () => {
    dispatch.modals.hideModal(Modals.ContactUsModal);
    dispatch.contactUs.setContactUsDefaultState();
    reset();
  };

  return (
    <form className={s['contact-us-form']} onSubmit={handleSubmit(onSubmit)}>
      {isGuest && (
        <>
          <div className={s['contact-us-row']}>
            <Input
              label="Your Name"
              type="text"
              className={s['contact-us-input']}
              error={errors.name?.message ?? ''}
              minLength={4}
              maxLength={60}
              placeholder="Enter your name"
              {...register('name', {
                pattern: {
                  message: 'Please type correct name (only letters and spaces)',
                  value: /^[a-zA-Z\s]*$/,
                },
              })}
            />
          </div>

          <div className={s['contact-us-row']}>
            <div className={s['contact-us-row-50']}>
              <FormPhoneInput
                label="Your Phone Number"
                labelClassName={s['contact-us-phone-label']}
                name="phone"
                control={control}
                className={s['contact-us-phone']}
                placeholder="Phone Number"
                country="us"
                disabled={false}
                error={errors.phone?.message ?? ''}
              />
            </div>
            <div className={s['contact-us-row-50']}>
              <Input
                label="Email Address"
                required
                type="text"
                className={s['contact-us-input']}
                error={errors.email?.message ?? ''}
                minLength={4}
                maxLength={200}
                placeholder="Enter your email address"
                {...register('email', {
                  required: true,
                  validate: (value) => {
                    if (validateEmail(value)) {
                      return true;
                    }
                    return 'Please type valid email address';
                  },
                })}
              />
            </div>
          </div>
        </>
      )}

      <div className={s['contact-us-row']}>
        <Textarea
          className={s['contact-us-input']}
          label="Question / Concern / Comment"
          required
          placeholder="Type details here"
          minRows={4}
          minLength={10}
          maxLength={2048}
          error={errors.text?.message ?? ''}
          {...register('text', {
            required: 'Please type valid text',
            validate: (value: string) => {
              if (value && value.length < 10) {
                return 'Please type at least of 10 characters.';
              }
            },
          })}
        />
      </div>

      {isGuest && (
        <div className={s['contact-us-row-captcha']}>
          <Controller
            name="captcha"
            control={control}
            rules={{required: true}}
            render={({field}) => (
              <div
                id={CONTACT_US_CAPTCHA_ID}
                className="visual-captcha-react"
                onClick={(event) => {
                  const eventTarget = event.target as HTMLDivElement;

                  // Hack to check if click on captcha image
                  const targetValue = eventTarget.classList.contains('visualCaptcha-img');
                  const childValue = eventTarget.querySelector('.visualCaptcha-img');

                  if (!targetValue && !childValue) {
                    return;
                  }

                  // try to get captcha value
                  field.onChange(getCaptchaValue(`#${CONTACT_US_CAPTCHA_ID}`));
                }}
              />
            )}
          />

          {errors.captcha && (
            <div className={s['captcha-error-message']}>
              {errors.captcha.type === 'server' ? 'Wrong captcha!' : 'Captcha is required!'}
            </div>
          )}
        </div>
      )}

      {serverError && <Alert type={AlertTypes.error} message={SERVER_ERROR} />}

      <div className={s['contact-us-row-buttons']}>
        <Button
          type="button"
          variant="outline"
          className={clsx(s['contact-us-button'])}
          onClick={hideModal}
          disabled={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          disabled={!isValid}
          loading={isSubmitting}
          className={s['contact-us-button']}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default ContactUsForm;
