import {FC} from 'react';
import {useSelector} from 'react-redux';

import NavLink from '~/common/NavLink/NavLink';
import {Icons, MenuIcons} from '~/common/Icon';
import Card from '~/common/display/Card/Card';
import {UserType} from '~/services/types';

import s from '../styles.module.scss';

const MyAccountLinks: FC<any> = ({onClickBack}) => {
  const {mailServerUrl, userType} = useSelector(({profile}) => profile.data);

  return (
    <Card className={s.card}>
      <NavLink icon={Icons.myAccount} label="My Account" carretLeft onClick={onClickBack} />
      {mailServerUrl && (
        <NavLink href="https://mail.hot.com" icon={Icons.myMailbox} label="My Mailbox" asChild />
      )}
      <NavLink href="/profile#/my-reviews" icon={Icons.myReviews} label="My Reviews" asChild />
      <NavLink
        href="/profile#/my-questions"
        icon={Icons.myQa}
        label="My Questions & Answers"
        asChild
      />
      {userType !== UserType.Regular && (
        <NavLink
          href="/profile#/connected-profiles"
          icon={MenuIcons.connectedProfiles}
          label="Connected Profiles"
          asChild
        />
      )}
      <NavLink
        href="/profile#/favorites/search"
        icon={Icons.myFavorites}
        label="Favorites"
        asChild
      />
    </Card>
  );
};

export default MyAccountLinks;
