import {Popover, Box, Overlay} from '@mantine/core';
import {FC, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useDisclosure} from '@mantine/hooks';

import {Dispatch, RootState} from '~/store/types';
import useMediaQuery from '~/hooks/useMediaQuery';
import {TNotification} from '~/services/hot-api/notifications';
import Icon, {Icons} from '~/common/Icon';

import {NotificationButton, NotificationModal, NotificationsList} from './parts';
import s from './styles.module.scss';

interface NotificationsProps {
  buttonClassName?: string;
}

const Notifications: FC<NotificationsProps> = ({buttonClassName}) => {
  const dispatch = useDispatch<Dispatch>();
  const {loading, response} = useSelector(
    (state: RootState) => state.profile.notifications,
    shallowEqual
  );

  const isMobile = useMediaQuery('(max-width: 767px)');
  const [notificationOpened, {open, close}] = useDisclosure(false);
  const [selectedNotification, setSelectedNotification] = useState<TNotification | null>(null);

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    dispatch.profile.loadNotifications();
  }, [dispatch]);

  return (
    <Box>
      {opened && <Overlay fixed />}
      <Popover
        position={isMobile ? 'bottom' : 'bottom-end'}
        width="min(100%, 480px)"
        withArrow
        radius="md"
        shadow="md"
        opened={opened}
        onChange={setOpened}
        zIndex={1000}
        withinPortal
        classNames={{
          dropdown: s['popover-dropdown'],
        }}
        portalProps={{
          target: document.body,
        }}
        arrowOffset={20}
      >
        <Popover.Target>
          <NotificationButton
            className={buttonClassName}
            onClick={() => setOpened((o) => !o)}
            count={response.unviewed}
          />
        </Popover.Target>
        <Popover.Dropdown>
          <Box className={s.dropdown__wrapper}>
            <div className={s.dropdown__header}>
              <div className={s.dropdown__title}>Notifications</div>
              <Icon
                name={Icons.cross}
                className={s['icon-close']}
                onClick={() => setOpened(false)}
              />
            </div>
            <NotificationsList
              notifications={response}
              loading={loading}
              onNotificationOpen={(notify) => {
                setSelectedNotification(notify);
                setOpened(false);
                open();
              }}
              onNotificationDelete={(notify) => {
                dispatch.profile.removeNotification(notify.id);
              }}
            />
          </Box>
        </Popover.Dropdown>
      </Popover>
      {selectedNotification && (
        <NotificationModal
          opened={notificationOpened}
          notification={selectedNotification}
          onClose={close}
        />
      )}
    </Box>
  );
};

export default Notifications;
