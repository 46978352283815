import {FC} from 'react';

import NavLink from '~/common/NavLink/NavLink';
import {Icons} from '~/common/Icon';
import Card from '~/common/display/Card/Card';

import s from '../styles.module.scss';

const MyHotlinksPageLink: FC<any> = ({onClickBack}) => {
  return (
    <Card className={s.card}>
      <NavLink icon={Icons.myHotlinks} label="My Hotlink Page" carretLeft onClick={onClickBack} />
      <NavLink icon={Icons.editHotlinksPage} label="Edit Hotlink Page" asChild />
      <NavLink icon={Icons.subscribers} label="Subscribers" asChild />
    </Card>
  );
};

export default MyHotlinksPageLink;
