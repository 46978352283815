import {FC, useEffect} from 'react';

import Modal from '~/common/display/Modal';
import {Modals, ContactUsQueryParams} from '~/modals/constants';
import ContactUsForm from '~/modals/ContactUsModal/parts/ContactUsForm';
import {ContactUsFinish} from '~/modals/ContactUsModal/parts/ContactUsFinish';
import {useDispatch, useSelector} from '~/store/index';

import s from './styles.module.scss';

const ContactUsModal: FC = () => {
  const dispatch = useDispatch();
  const {success} = useSelector((state) => state.contactUs);
  const instanceInfo = useSelector(({app}) => app.data.instanceInfo);
  const {visible, payload} = useSelector((state) => state.modals[Modals.ContactUsModal]);

  /**
   * Get query params from link & pass to modal for submit
   */
  useEffect(() => {
    if (visible) {
      const payloadData = {};

      if (payload?.href?.includes('?')) {
        const [_, hrefSearch] = payload.href.split('?');

        if (hrefSearch) {
          const params = new URLSearchParams(hrefSearch);

          ContactUsQueryParams.forEach((id) => {
            if (params.get(id)) {
              payloadData[id] = params.get(id);
            }
          });
        }
      }

      dispatch.contactUs.setContactUsDefaultState();
      dispatch.modals.showModal({key: Modals.ContactUsModal, payload: payloadData});
    }
  }, [visible]);

  const onClose = () => dispatch.modals.hideModal(Modals.ContactUsModal);

  return (
    <Modal
      opened={visible}
      onClose={onClose}
      title="Contact Us"
      className={s['contact-us-modal']}
      classNames={{
        title: s['contact-us-modal-title'],
      }}
    >
      {success && <ContactUsFinish siteName={instanceInfo.label} />}

      {!success && <ContactUsForm {...payload} />}
    </Modal>
  );
};

export default ContactUsModal;
