import {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {Dispatch} from '~/store/types';
import ContactUsModal from '~/modals/ContactUsModal';
import RemovalRequestModal from '~/modals/RemovalRequestModal';
import {ImagePreviewModal} from '~/modals/ImagePreviewModal/ImagePreviewModal';

import Header from './components/Header';
import {HeaderType} from './components/Header/Header';

const getHeaderType = () => {
  const {APP_CONFIG} = window;

  if (APP_CONFIG.page.isRootPage) {
    return HeaderType.Main;
  }

  if (APP_CONFIG.page.isSearchPage) {
    return HeaderType.Search;
  }

  if (APP_CONFIG.page.isModal) {
    return HeaderType.Light;
  }

  return HeaderType.PersonalArea;
};

const App: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const headerType = getHeaderType();

  useEffect(() => {
    dispatch.profile.startNotificationsPulling();
  }, []);

  return (
    <>
      <Header variant={headerType} />
      <RemovalRequestModal />
      <ContactUsModal />
      <ImagePreviewModal />
    </>
  );
};

export default App;
