// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card--pGloq{margin-top:9px}`, "",{"version":3,"sources":["webpack://./src/app-header/components/HeaderMenu/parts/Links/styles.module.scss"],"names":[],"mappings":"AAAA,aACE,cAAA","sourcesContent":[".card {\n  margin-top: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `card--pGloq`
};
export default ___CSS_LOADER_EXPORT___;
