import {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import {RootState} from '~/store/types';
import Modal from '~/common/display/Modal';
import {Modals} from '~/modals/constants';
import RemovalRequestForm from '~/modals/RemovalRequestModal/parts/RemovalRequestForm';
import {RemovalRequestFinish} from '~/modals/RemovalRequestModal/parts/RemovalRequestFinish';
import {RemovalRequestHeader} from '~/modals/RemovalRequestModal/parts/RemovalRequestHeader';
import useMediaQuery from '~/hooks/useMediaQuery';
import {isMobileDevice} from '~/utils/browserDetect';

import s from './styles.module.scss';

const RemovalRequestModal: FC = () => {
  const dispatch = useDispatch();
  const {success} = useSelector((state: RootState) => state.removalRequest);
  const instanceInfo = useSelector(({app}: RootState) => app.data.instanceInfo);
  const {visible} = useSelector((state: RootState) => state.modals[Modals.RemovalRequestModal]);
  const isFullHD = useMediaQuery('(max-height: 1000px)') && !isMobileDevice();

  useEffect(() => {
    if (visible) {
      dispatch.removalRequest.setRemovalRequestDefaultState();
    }
  }, [visible]);

  const onClose = () => dispatch.modals.hideModal(Modals.RemovalRequestModal);

  const titleClassName = clsx(s['removal-request-modal-title'], {
    [s['removal-request-modal-title--fhd']]: isFullHD,
  });

  return (
    <Modal
      opened={visible}
      onClose={onClose}
      title="Removal Request"
      classNames={{
        title: titleClassName,
      }}
      className={clsx(s['removal-request-modal'], {[s['removal-request-modal--fhd']]: isFullHD})}
      withBottomPadding={!isFullHD}
      transition="pop"
    >
      {success && <RemovalRequestFinish siteName={instanceInfo.label} />}

      {!success && (
        <>
          <RemovalRequestHeader siteName={instanceInfo.label} isFullHD />
          <RemovalRequestForm isFullHD />
        </>
      )}
    </Modal>
  );
};

export default RemovalRequestModal;
