import {FC, useEffect} from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import clsx from 'clsx';

import Icon, {Icons} from '~/common/Icon';
import Modal from '~/common/display/Modal';
import {useDispatch, useSelector} from '~/store/index';

import {Modals} from '../constants';
import s from './styles.module.scss';

export const ImagePreviewModal: FC = () => {
  const dispatch = useDispatch();
  const {visible, payload} = useSelector((state) => state.modals[Modals.ImagePreview]);

  const onClose = () => {
    dispatch.modals.hideModal(Modals.ImagePreview);
  };

  return (
    <Modal
      opened={visible}
      classNames={{
        inner: s.inner,
        body: s.body,
      }}
      className={s.modal}
      withCloseButton={false}
      onClose={onClose}
      transition="fade"
    >
      <div className={s['preview-modal-header']}>
        <Icon name={Icons.cross} onClick={onClose} className={s['preview-modal-close-icon']} />
      </div>
      <div className={s['preview-modal-container']}>
        <ImageGallery
          showThumbnails={false}
          items={payload.images}
          startIndex={payload.index}
          showPlayButton={false}
          showFullscreenButton={false}
          showBullets
          onImageError={(event) => {
            const {src} = event.nativeEvent.target;

            const newImages = payload.images.map((item) => {
              if (item.original !== src) {
                return item;
              }
              return {
                original: '/img/app/image-placehoder.png',
                thumbnail: '/img/app/image-placehoder.png',
              };
            });

            dispatch.modals.setPayload({
              key: Modals.ImagePreview,
              payload: {
                index: payload.index,
                images: newImages,
              },
            });
          }}
          renderLeftNav={(onClick) => (
            <Icon
              name={Icons.carretRight}
              className={clsx(s.arrow, s['arrow--left'])}
              onClick={onClick}
            />
          )}
          renderRightNav={(onClick) => (
            <Icon
              name={Icons.carretRight}
              className={clsx(s.arrow, s['arrow--right'])}
              onClick={onClick}
            />
          )}
        />
      </div>
    </Modal>
  );
};
