import {CSSProperties, FC} from 'react';

import s from './styles.module.scss';
import OvalLoaderIcon from './parts/OvalLoaderIcon';
import DotsLoaderIcon from './parts/DotsLoaderIcon';

interface LoaderProps {
  className?: string;
  size?: number;
  mt?: CSSProperties['marginTop'];
  variant?: 'oval' | 'dots';
}

const LoaderVariantsMap = {
  oval: OvalLoaderIcon,
  dots: DotsLoaderIcon,
};

const Loader: FC<LoaderProps> = ({className, size = 60, mt = 0, variant = 'oval'}) => {
  const Component = LoaderVariantsMap[variant];

  return (
    <div className={s.loader} style={{marginTop: mt}}>
      <Component size={size} className={className} />
    </div>
  );
};

export default Loader;
