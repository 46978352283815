import {FC} from 'react';
import {MemoryRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import ErrorBoundary from '~/common/ErrorBoundary';
import {renderApp} from '~/loader/render';
import {AppProps} from '~/loader/types';

import App from './App';
import HamburgerError from './components/HamburgerError/HamburgerError';
import {AppIds} from '../constants';

const Root: FC<AppProps> = ({store}) => (
  <Provider store={store}>
    <ErrorBoundary errorBody={<HamburgerError />}>
      <MemoryRouter>
        <App />
      </MemoryRouter>
    </ErrorBoundary>
  </Provider>
);

renderApp(AppIds.HeaderApp, Root);
